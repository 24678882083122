.page {
  padding: 4rem 0rem;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: var(--background-primary);
  box-sizing: border-box;
}

.mainContainer {
  background-color: var(--static-white);
  border-radius: 2.4rem;
  padding: 2.4rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.6rem;
}

.practiceListText {
  color: var(--text-strong);
  font-size: 2.2rem;
  margin: 0;
  font-weight: bold;
}

.reserveButton {
  background-color: var(--static-white);
  border: solid 1px var(--line-normal);
  border-radius: 0.8rem;
  padding: 0.6rem 0.8rem;
  font-size: 1.4rem;
  cursor: pointer;
  text-align: center;
  color: var(--text-alternative);
}
.tutorialButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  width: 12.2rem;
  height: 2.8rem;
  margin-top: 3.5rem;
  padding: 1rem 1.6rem;
  margin-left: auto;
  border-radius: 8px;
  font-weight: 600;
  border: none;
  background-color: #13cdbe;
  color: #fff;
  cursor: pointer;
}
.electircIcon {
  width: 24px;
  height: 24px;
}
