.container{
  background-color: var( --background-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.customContainer {
  background-color: var(--static-white);
  width: 34.3rem;
  height: 27.6rem;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  
}

.p1Text{
font-size: 2.8rem;
 font-weight: bold;
 margin: 0;
}
.p2Text{
  font-size: 1.6rem;
  margin-bottom: 2.4rem;
}
.customButton {
  background-color: var(--primary-normal);
  display: flex; 
  justify-content: center; 
  align-items: center; 
  cursor: pointer;
  border-radius: 8px;
  min-width: 230px;
}
.CheckButtonContainer{
  margin-bottom: 1rem;
  min-width: 30px;
}
.CheckButtonImg{
 
  min-width: 30px;
}
.customButtonText {
  position: relative;
  font-size: 1.3rem;
  font-weight: 500; /* Semibold 두께 */
  color: #ffffff;   
}
