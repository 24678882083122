.page {
  padding: 4rem 0rem;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: var(--background-primary);
  box-sizing: border-box;
}
.mainContainer {
  background-color: var(--static-white);
  border-radius: 2.4rem;
  padding: 2.4rem;
}
.textcontainer{
  margin-left: 2.4rem;
}
.TitleText{
  font-weight: bold;
  font-size: 3.2rem;
  margin: 0;
  margin-top: 1.6rem;
 }
 .TitleText2{
   font-weight: bold;
   font-size: 3.2rem;
   margin: 0;
 }
 .inputContainer {
  display: flex;
  align-items: center; 
  justify-content: space-between; 
  margin-top: 2.5rem;
} 
.inputContainer2 {
  display: flex;
  align-items: center; 
  justify-content: space-between; 
  margin-top: 4rem;
}
.p2Text{
  font-weight: 600;
  font-size: 2rem;
  margin-right: 2.4rem; /* 버튼과의 간격 조절 */
}
.flexBlank {
  flex-grow: 1;
}
.Input{
  display: inline-block;
  border-radius: 8px;
  border: none;
  background-color: var(--fill-normal);
  width: 37rem;
  padding: 1.3rem 1.6rem;
  font-size: 1.2rem;
}
.Input::placeholder {
  color: var(--text-assistive);
}
.Input:focus{
  outline: none;
}
.addButton{
  margin-top: 23.5rem;
  padding: 1rem 3rem; 
  margin-left: auto; /* 왼쪽 마진 자동으로 설정하여 오른쪽으로 이동 */
  display: block; /* 블록 요소로 설정 */
  font-size: 1.3rem;
  border-radius: 8px;
  font-weight: 600;
  border: 1px solid #E9ECEC; /* 테두리 색상 */
  background-color: var(--fill-disable);
}
.addButton.enabled {
  background-color: #7de8df; /* 활성화된 상태 버튼 색상 */
  color: #fff; /* 활성화된 상태 글자 색상 */
  cursor: pointer;
}