.container{
  background-color: var( --background-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.customContainer {
  background-color: var(--static-white);
  width: 80rem;
  height: 51.2rem;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
  
}
.CheckButtonContainer{
  margin-bottom: 1rem;
}
.p1Text{
  font-size: 4rem;
 font-weight: bold;
 margin: 0;
}
.p2Text{
  font-size: 1.6rem;
  margin-bottom: 4.5rem;
}
.customButton {
  background-color: var(--primary-normal);
  display: flex; 
  justify-content: center; 
  align-items: center; 
  cursor: pointer;
  border-radius: 8px;
 
}
.customButtonText {
  position: relative;
  font-size: 1.3rem;
  font-weight: 500; /* Semibold 두께 */
  color: #ffffff;   
}
