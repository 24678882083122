/* FileList.module.css */
.fileTable {
    width: 100%;
    border-collapse: collapse;
}

.fileTable thead th:first-child {
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
}

.fileTable thead th:last-child {
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
}   

.fileTable th {
    background-color: var(--fill-assistive);
    font-weight: bold;
    padding: 12px;
    font-size: 1.4rem;
    color: var(--text-strong);
}

.fileTable td {
    border: none;
    padding: 12px;
    text-align: center;
    font-size: 1.4rem;
    color: var(--text-strong);
}

.fileItem {
    border-bottom: 1px solid var(--line-assistive);
}

.fileItem:hover {
    background-color: #f1f3f5;
    cursor: pointer;
}

/* Status styles */
.status {
    padding: 0.5rem 1rem;
    border-radius: 0.8rem;
    font-weight: bold;
    display: inline-block;
    text-align: center;
}

.notStarted {
    background-color: var(--fill-normal);
    color: var(--text-alternative);
}

.inProgress {
    background-color: var(--primary-chip);
    color: var(--primary-text);
}

.complete {
    background-color: var(--primary-strong);
    color: var(--static-white);
}

.cancelled {
    background-color: var(--negative-chip);
    color: var(--negative-text);
}

/* Partner style tags */
.partnerStyle {
    padding: 0.5rem 1rem;
    border-radius: 0.8rem;
    font-weight: bold;
    display: inline-block;
    text-align: center;
}

.positive {
    background-color: var(--primary-chip);
    color: var(--primary-text);
}

.negative {
    background-color: var(--negative-chip);
    color: var(--negative-text);
}

.random {
    background-color: var(--random-chip);
    color: var(--random-text);
}

/* Additional styles for table and alignment */
.fileTable td {
    color: var(--text-normal);
    font-size: 1.4rem;
}

.fileTable p {
    margin: 0.4rem;
}

.fileTable td:first-child {
    font-weight: bold;
    font-size: 1.6rem;
}