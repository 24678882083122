/* Loading.module.css */
.container {
  background-color: var( --background-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.customContainer {
  background-color: var(--static-white);
  width: 80rem;
  height: 51.2rem;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
  }

.loadingText {
    color: var(--primary-strong); 
    font-size: 1.6rem;
    font-weight: 500;
    animation: fadeInOut 2s infinite;
    margin-bottom: 1.2rem;
}

@keyframes fadeInOut {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}