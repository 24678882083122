.react-datepicker__header {
    background-color: #E9ECEC
}

.react-datepicker__navigation-icon--previous::before {
    border-color: #8A9393;
}

.react-datepicker__navigation-icon--next::before {
    border-color: #8A9393;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: #F1F3F3;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: #F1F3F3;
    color: #000;
}

/* DatePicker 전체 크기 조정 */
.react-datepicker {
    font-size: 1.2em; /* 기본 폰트 크기 조정 */
  }
  
  .react-datepicker__header {
    padding: 20px; /* 헤더 패딩 조정 */
  }
  
  .react-datepicker__day, .react-datepicker__day-name, .react-datepicker__current-month {
    font-size: 1.2em; /* 날짜 및 월 이름 폰트 크기 조정 */
    padding: 0.8em; /* 날짜의 패딩 크기 조정 */
  }
  
  .react-datepicker__navigation {
    top: 20px; /* 네비게이션 버튼 위치 조정 */
  }
  
  .react-datepicker__input-container input {
    font-size: 1.2em; /* 입력 필드 크기 조정 */
    padding: 12px 15px;
  }
  
  .react-datepicker__month {
    margin: 10px; /* 전체 달력의 마진 조정 */
  }
  
  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    font-size: 1.4rem;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
    background-color: #8A9393;
  }