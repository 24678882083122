.container{
  background-color: var( --background-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.customContainer {
  background-color: var(--static-white);
  width: 80rem;
  height: 51.2rem;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
}
.logincontainer {
  display: flex;
  justify-content: center;
}
.loginText{
  color: var(--primary-strong); 
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1.2rem;
}
.imageContainer {
  display: flex; /* 가로 방향으로 정렬 */
  margin-bottom: 3.2rem;
}
.Frameimg{
  margin-right: 2rem; /* Frame 이미지 오른쪽에 간격 추가 */
}
.Frame{
  width: 100%;
}
.Logotypeimg{
margin-top: 0.8rem;
}
.Logotype{
  width: 100%;
}



.loginButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
}
.KakaoLogin {
  width: 100%;

}
