.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .icon {
    width: 40px;
    height: 40px;
  }
  
  .title {
    margin: 0;
    font-size: 4rem;
    font-weight: bold;
    color: var(--text-strong);
    margin-top: 1.2rem;
  }
  
  .description {
    margin: 0;
    font-size: 1.6rem;
    color: var(--text-normal);
    margin-top: 0.8rem;
  }
  
  .confirmButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44.4rem;
    height: 4.8rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--static-white);
    background-color: var(--primary-normal);
    border: none;
    border-radius: 0.8rem;
    cursor: pointer;
    margin-top: 2.4rem;
  }
  
  .confirmButton:hover {
    background-color: #13b2a6;
  }  