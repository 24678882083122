.page {
  padding: 4rem 0rem;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: var(--background-primary);
  box-sizing: border-box;
}

.mainContainer {
  background-color: var(--static-white);
  border-radius: 2.4rem;
  padding: 2.4rem;
}

.title {
  font-size: 3.2rem;
  font-weight: bold;
  margin-top: 1.6rem;
  margin-bottom: 4rem;
  color: var(--text-strong);
}
.subtitle {
  font-size: 22px;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 20px;
  color: var(--text-strong);
}

.flexBlank {
  flex-grow: 1;
}

.optionGroup {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 6.4rem;
}

.optionTitle {
  margin: 0;
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--text-strong);
}

.optionButtonGroup {
  display: flex;
  gap: 0.8rem;
  justify-content: end;
}

.optionButtonColumnGroup {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.optionButton {
  min-width: 12.8rem;
  height: 4rem;
  padding: 0.8rem 1.6rem;
  background-color: var(--fill-normal);
  cursor: pointer;
  font-size: 1.6rem;
  border-radius: 0.8rem;
  border: none;
  color: var(--text-alternative);
  font-weight: 600;
}

.optionButton.selected {
  background-color: var(--fill-heavy);
}

.phoneInput {
  width: 40rem;
  padding: 1.2rem 1.8rem;
  font-size: 1.6rem;
  color: var(--text-alternative);
  border: none;
  background-color: var(--fill-assistive);
  border-radius: 0.8rem;
}

.phoneInput::placeholder {
  color: var(--text-assistive);
}

.phoneInput:focus {
  outline: none;
}

.nextButton {
  width: 12.8rem;
  height: 4.8rem;
  border-radius: 0.8rem;
  background-color: var(--fill-disable);
  border: none;
  font-size: 1.6rem;
  cursor: pointer;
  font-weight: 600;
}

.primary {
  background-color: var(--primary-normal);
  color: var(--static-white);
}

.disabled {
  background-color: var(--fill-disable);
  color: var(--text-disable);
}
.profileContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  overflow-x: scroll;
}

.profileContainer::-webkit-scrollbar {
  display: none;
}
.profileCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 118px;
  margin-right: 8px;
  padding: 8px;
  /* padding-top: 12px; */
  /* padding-bottom: 12px; */
  border-radius: 8px;
  cursor: pointer;
}

.profileName {
  font-size: 16px;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 6px;
}

.profileEmail {
  font-size: 12px;
  color: var(--text-alternative);
}
.profileImage {
  width: 40px;
  height: 40px;
}
.addTeamBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 118px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: var(--fill-normal);
  border-radius: 8px;
  cursor: pointer;
}
.addTeamContent {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-assistive);
}
