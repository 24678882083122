.sideSection {
  width: 24rem;
}

.sidebar {
  background-color: var(--static-white);
  width: 24rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  border-radius: 16px;
  position: fixed;
  box-sizing: border-box;
}

.Signitureimg {
  margin-left: 3.2rem;
  margin-top: 4rem;
}

.NavLogo {
  width: 100%;
  padding-bottom: 3rem;
  /* 여백 추가 */
  cursor: pointer;
}

.ManagementButton {
  border-top: 1px solid #e9ecec;
  /* 밑줄 추가 */
  margin-right: 3rem;
  margin-top: 3rem;
  margin-left: 3rem;
  cursor: pointer;
}

.ManagementContent {
  display: flex;
  align-items: center;
  /* 아이콘과 텍스트가 세로로 가운데 정렬 */
  background-color: #ffffff;
  /* 버튼 배경색 설정 */
  padding: 0.8rem;
  border-radius: 8px;
  margin-top: 2rem;
}

.ManagementIcon {
  margin-right: 0.9rem;
  /* 텍스트와 아이콘 간의 간격 */
}

.ManagementText {
  font-size: 1.8rem;
  /* 텍스트 크기 조정 */
  margin: 0;
  /* 텍스트 주변 여백 제거 */
  color: #8a9393;
}

.ReservationButton {
  margin-top: 2rem;
  margin-left: 3rem;
  margin-right: 3rem;
  cursor: pointer;
}

.ReservationContent {
  display: flex;
  align-items: center;
  /* 아이콘과 텍스트가 세로로 가운데 정렬 */
  background-color: #ffffff;
  /* 버튼 배경색 설정 */
  padding: 0.8rem;
  border-radius: 8px;
}

.ReservationIcon {
  margin-right: 0.9rem;
  /* 텍스트와 아이콘 간의 간격 */
}

.ReservationText {
  font-size: 1.8rem;
  /* 텍스트 크기 조정 */
  margin: 0;
  /* 텍스트 주변 여백 제거 */
  color: #8a9393;
}

.nav {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e9ecec;
  /* 밑줄 추가 */
  margin-top: auto;
  /* nav를 하단으로 이동 */
  margin-bottom: 5rem;
  margin-left: 3rem;
  margin-right: 2rem;
}

.CustomerButton {
  margin-top: 2rem;

  cursor: pointer;
}

.CustomerContent {
  display: flex;
  align-items: center;
  /* 아이콘과 텍스트가 세로로 가운데 정렬 */
  background-color: #ffffff;
  /* 버튼 배경색 설정 */
  padding: 0.8rem;
  border-radius: 8px;
}

.CustomerIcon {
  margin-right: 0.9rem;
  /* 텍스트와 아이콘 간의 간격 */
}

.CustomerText {
  font-size: 1.8rem;
  /* 텍스트 크기 조정 */
  margin: 0;
  /* 텍스트 주변 여백 제거 */
  color: #8a9393;
}

.PartnerButton {
  margin-top: 2rem;
  cursor: pointer;
}

.PartnerContent {
  display: flex;
  align-items: center;
  /* 아이콘과 텍스트가 세로로 가운데 정렬 */
  background-color: #ffffff;
  /* 버튼 배경색 설정 */
  padding: 0.8rem;
  border-radius: 8px;
}

.PartnerIcon {
  margin-right: 0.9rem;
  /* 텍스트와 아이콘 간의 간격 */
}

.PartnerText {
  font-size: 1.8rem;
  /* 텍스트 크기 조정 */
  margin: 0;
  /* 텍스트 주변 여백 제거 */
  color: #8a9393;
}

.link {
  padding: 10px 20px;
  text-decoration: none;
  color: #00c2c2;
  font-size: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  transition: background 0.3s;
}

.linkDisabled {
  padding: 10px 20px;
  text-decoration: none;
  color: #b0b3b5;
  font-size: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  pointer-events: none;
}

.loginButton {
  width: 100%;
  background-color: var(--primary-normal);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: 3.2rem;
  margin-bottom: 2.4rem;
}

.loginText {
  position: relative;
  font-size: 1.3rem;
  font-weight: 600;
  color: #ffffff;
}

.activeButton .ManagementContent,
.activeButton .ReservationContent,
.activeButton .CustomerContent,
.activeButton .PartnerContent {
  background-color: #e3fcfa;
}

.activeButton .ManagementText,
.activeButton .ReservationText,
.activeButton .CustomerText,
.activeButton .PartnerText {
  color: #13cdbe;
}

.activeButton .ManagementIcon,
.activeButton .ReservationIcon,
.activeButton .CustomerIcon,
.activeButton .PartnerIcon {
  filter: invert(50%) sepia(91%) saturate(452%) hue-rotate(131deg)
    brightness(99%) contrast(101%);
}
