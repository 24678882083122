.container{
  background-color: var( --background-primary);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100vh;
 
}
.customContainer {
  background-color: var(--static-white);
  width: 88rem;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
}
.p1Text{
  font-size: 1rem;
  margin-top: 3rem;
  font-weight: bold;
  font-size: 3.2rem;
  margin-left: 2.4rem;
}
.fileText{
  margin-left: 2.4rem;
}
.textcontainer2 {
  display: flex; /* 수평 정렬을 위해 flex 사용 */
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: space-between; /* 텍스트와 버튼 간 간격을 균등하게 */
  margin-bottom: 4rem; 
}
.p2Text{
  font-weight: 600;
  font-size: 1.8rem;
 
}

.customButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:8px;
  border: 1px solid #E9ECEC; 
  background-color: #E9ECEC; /* 버튼 배경색 설정 */
  padding: 0.5rem 0.9rem; /* 위아래 0.6rem, 오른쪽 왼쪽 0.8rem */
  margin-right: 2.4rem; /* 버튼의 오른쪽 여백 */
}
.customButtonText {
  position: relative;
  font-size: 1.4rem;
  font-weight: 600; /* Semibold 두께 */
  color: #525B5A;   
  margin: 0;
  padding: 0;
}
.customButton2 {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:8px;
  border: 1px solid #E3FCFA; 
  background-color: #E3FCFA; /* 버튼 배경색 설정 */
  padding: 0.5rem 0.9rem; /* 위아래 0.6rem, 오른쪽 왼쪽 0.8rem */
  margin-right: 2.4rem; /* 버튼의 오른쪽 여백 */
}
.customButtonText2 {
  position: relative;
  font-size: 1.4rem;
  font-weight: 600; /* Semibold 두께 */
  color: #525B5A;   
  margin: 0;
  padding: 0;
}
.p3Text{
  font-weight: 600;
  font-size: 1.8rem;
 
}
.p4Text{
  font-weight: 600;
  font-size: 1.8rem;

}
.p5Text{
  font-weight: 600;
  font-size: 1.8rem;
 
}
.p6Text{
  font-weight: 600;
  font-size: 1.8rem;
 
}