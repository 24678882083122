@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/pretendard/Pretendard-Regular.woff') format('woff');
  font-weight: 400; /* Normal weight */
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('./assets/fonts/pretendard/Pretendard-Bold.woff') format('woff');
  font-weight: 700; /* Bold weight */
  font-style: normal;
}

body {
  font-family: 'Pretendard'; 
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea{
  font-family: 'Pretendard'; 
}

input{
  font-family: 'Pretendard'; 
}