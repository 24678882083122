
/* 1280px 기준으로 설정: 1rem = 10px */
html {
    font-size: 10px; /* 1rem = 10px */
}

/* 더 작은 데스크탑 (~ 1280px) */
@media (min-width: 768px) and (max-width: 1024px) {
    html {
        font-size: 6px; /* 1rem = 9px */
    }
}

/* 작은 데스크탑 (~ 1280px) */
@media (min-width: 1025px) and (max-width: 1280px) {
    html {
        font-size: 8px; /* 1rem = 10px */
    }
}

/* 보통 데스크탑 (1281px ~ 1440px) */
@media (min-width: 1281px) and (max-width: 1440px) {
    html {
        font-size: 10px; /* 1rem = 11px */
    }
}

/* 큰 데스크탑 (1441px ~ 1920px, Full HD) */
@media (min-width: 1441px) and (max-width: 1920px) {
    html {
        font-size: 11px; /* 1rem = 12px */
    }
}

/* 초대형 데스크탑 (1921px ~ 2560px, QHD/UHD) */
@media (min-width: 1921px) and (max-width: 2560px) {
    html {
        font-size: 12px; /* 1rem = 13px */
    }
}

/* 초고해상도 (2561px 이상, 4K/UHD 이상) */
@media (min-width: 2561px) {
    html {
        font-size: 13px; /* 1rem = 14px */
    }
}

/* Color Token */
:root {
    /* Static Colors */
    --static-white: #FFFFFF; /* RP/Neutral 0 */
    --static-black: #000000; /* RP/Neutral 100 */

    /* Primary Colors */
    --primary-chip: #E3FCFA; /* RP/Primary 5 */
    --primary-button: #C0F6F2; /* RP/Primary 15 */
    --primary-assistive: #7DE8DF; /* RP/Primary 30 */
    --primary-normal: #13CDBE; /* RP/Primary 50 */
    --primary-strong: #11BBAD; /* RP/Primary 55 */
    --primary-heavy: #0EA093; /* RP/Primary 60 */
    --primary-text: #0B7A71; /* RP/Primary 70 */

    /* Text Colors */
    --text-strong: #000000; /* RP/Neutral 100 */
    --text-normal: #222525; /* RP/Green Gray 90 */
    --text-alternative: #525B5A; /* RP/Green Gray 60 */
    --text-assistive: #8A9393; /* RP/Green Gray 40 */
    --text-disable: #ADADAD; /* RP/Neutral 30 */

    /* Fill Colors */
    --fill-assistive: #F1F3F3; /* RP/Green Gray 5 */
    --fill-normal: #E9ECEC; /* RP/Green Gray 10 */
    --fill-strong: #DFE2E2; /* RP/Green Gray 15 */
    --fill-heavy: #D1D6D6; /* RP/Green Gray 20 */
    --fill-disable: #EBEBEB; /* RP/Neutral 10 */

    /* Interaction Colors */
    --interaction-strong: #F1F3F366; /* RP/Green Gray 5 */
    --interaction-heavy: #F1F3F3CC; /* RP/Green Gray 5 */

    /* Line Colors */
    --line-assistive: #E9ECEC; /* RP/Green Gray 10 */
    --line-normal: #DFE2E2; /* RP/Green Gray 15 */
    --line-strong: #D1D6D6; /* RP/Green Gray 20 */
    --line-heavy: #C4CAC9; /* RP/Green Gray 25 */
    --line-disable: #E0E0E0; /* RP/Neutral 15 */

    /* Background Colors */
    --background-primary: #F7F8F8; /* RP/Green Gray 2 */
    --background-secondary: #FFFFFF; /* RP/Neutral 0 */

    /* Negative Colors */
    --negative-chip: #FDE3E7; /* RP/Red 5 */
    --caution: #E51A3C; /* RP/Red 50 */
    --negative-text: #970C23; /* RP/Red 70 */

    /* Random Colors */
    --random-chip: #F2E3FD; /* RP/Violet 5 */
    --random-text: #5D0C97; /* RP/Violet 70 */

    /* Dim Colors */
    --dim: #181B1ABF; /* RP/Opacity 75 */
}
