.container{
  background-color: var( --background-primary);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100vh;
}
.customContainer {
  background-color: var(--static-white);
  width: calc(100% - 5rem); /* 2.5rem씩 양쪽 마진을 고려한 너비 */
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin: 2.5rem; /* 양쪽에 2.5rem 마진 (40px에 해당) */
}
.textcontainer{
  margin-left: 2.4rem;
}
.p1Text{
 font-weight: bold;
  font-size: 3.2rem;
  
}
.p2Text{
  font-weight: bold;
  font-size: 2rem;
  margin-right: 2.4rem; /* 버튼과의 간격 조절 */
}
.p3Text{
  font-weight: bold;
  font-size: 2rem;
}
.textcontainer2 {
  display: flex; /* 수평 정렬을 위해 flex 사용 */
  align-items: center; /* 수직 중앙 정렬 */
  
}
.customButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:8px;
  border: 1px solid #E3FCFA; 
  background-color: #E3FCFA; /* 버튼 배경색 설정 */
  padding: 0.5rem 0.9rem; /* 위아래 0.6rem, 오른쪽 왼쪽 0.8rem */
  
}

.customButtonText {
  position: relative;
  font-size: 1.4rem;
  font-weight: 600; /* Semibold 두께 */
  color: #0B7A71;   
  margin: 0;
  padding: 0;
}
.customButton2{
  
  margin-right: 1.5rem; /* 24px -> 1.5rem */
}
.buttonContent {
  display: flex;
  align-items: center; /* 아이콘과 텍스트가 세로로 가운데 정렬 */
  background-color: #F1F3F3; /* 버튼 배경색 설정 */ 
  padding: 0.8rem;
  border-radius: 8px;
}

.customButtonIcon {
 
  margin-right: 0.6rem; /* 텍스트와 아이콘 간의 간격 */
}

.customButtonText2 {
  font-size: 1.6rem; /* 텍스트 크기 조정 */
  margin: 0; /* 텍스트 주변 여백 제거 */
}
.customOkButton {
  width: 100%;
  background-color: var(--primary-normal);
  display: flex; 
  justify-content: center; 
  align-items: center; 
  cursor: pointer;
  border-radius: 8px;
  margin-top: 4rem;
  margin-left: auto; /* 오른쪽으로 이동 */
  margin-right: 1.7rem; /* 오른쪽 여백 추가 */
  margin-bottom: 2.4rem;
 
}
.customOkButtonText {
  position: relative;
  font-size: 1.3rem;
  font-weight: 500; /* Semibold 두께 */
  color: #fff;
  
}