.container {
  background-color: var(--background-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.customContainer {
  background-color: var(--static-white);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin: 2.5rem 5rem;
}
.p1Text {
  margin-left: 2.4rem;
  font-weight: bold;
  font-size: 3.2rem;
}
.textContainer {
  margin-left: 2.4rem;
  display: flex; /* 수평 정렬을 위해 flex 사용 */
  align-items: center; /* 수직 중앙 정렬 */
}
.p2Text {
  font-weight: 600;
  font-size: 1.8rem;
  margin-right: 59.5rem; /* 버튼과의 간격 조절 */
}
.customButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #dfe2e2; /* 검은색 테두리 추가 */
  padding: 0.8rem 0.8rem; /* 위아래 0.6rem, 오른쪽 왼쪽 0.8rem */
  margin-right: 1rem;
}
.customButtonText {
  position: relative;
  font-size: 1.3rem;
  font-weight: 500; /* Semibold 두께 */
  color: #000000;
  margin: 0;
  padding: 0;
}
.inputField {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.2rem;
  border: 1px solid #dfe2e2; /* 테두리 스타일 */
  border-radius: 8px; /* 모서리 둥글게 */
  font-size: 1.6rem; /* 폰트 크기 */
  width: 83.2rem;
  min-height: 36rem;
  margin-left: 2.4rem;
  margin-right: 2.4rem;
  color: #000000; /* 텍스트 색상 */
  overflow-x: auto; /* 가로 스크롤 활성화 */
  resize: none; /* 크기 조절 비활성화 */
  background-color: #f1f3f3;
}
.textContainer2 {
  margin-left: 2.4rem;
  display: flex; /* 수평 정렬을 위해 flex 사용 */
  align-items: center; /* 수직 중앙 정렬 */
  margin-top: 4rem;
}
.p3Text {
  font-weight: 600;
  font-size: 1.8rem;
  margin-right: 63rem; /* 버튼과의 간격 조절 */
}
.inputField2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.2rem;
  border: 1px solid #dfe2e2; /* 테두리 스타일 */
  border-radius: 8px; /* 모서리 둥글게 */
  font-size: 1.6rem; /* 폰트 크기 */
  width: 83.2rem;
  min-height: 16rem;
  margin-left: 2.4rem;
  margin-right: 2.4rem;
  color: #000000; /* 텍스트 색상 */
  overflow-x: auto; /* 가로 스크롤 활성화 */
  resize: none; /* 크기 조절 비활성화 */
  background-color: #f1f3f3;
}
.textContainer3 {
  margin-left: 2.4rem;
  display: flex; /* 수평 정렬을 위해 flex 사용 */
  align-items: center; /* 수직 중앙 정렬 */
  margin-top: 4rem;
}
.customOkButton {
  width: 100%;
  background-color: var(--primary-normal);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 3rem;
  margin-left: auto; /* 오른쪽으로 이동 */
  margin-right: 3.2rem; /* 오른쪽 여백 추가 */
  margin-bottom: 2.4rem;
}
.customOkButtonText {
  position: relative;
  font-size: 1.3rem;
  font-weight: 600; /* Semibold 두께 */
  color: #fff;
}
