/* FileList.module.css */
.fileTable {
  width: 100%;
  border-collapse: collapse;
}

/* Partner style tags */
.partnerStyle {
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;
  font-weight: bold;
  display: inline-block;
  text-align: center;
}

.positive {
  background-color: var(--primary-chip);
  color: var(--primary-text);
}

.negative {
  background-color: var(--negative-chip);
  color: var(--negative-text);
}

.random {
  background-color: var(--random-chip);
  color: var(--random-text);
}

/* Additional styles for table and alignment */
.fileTable td {
  color: var(--text-normal);
  font-size: 1.4rem;
}

.fileTable p {
  margin: 0.4rem;
}

.fileTable td:first-child {
  font-weight: bold;
  font-size: 1.6rem;
}
/* 기본 색상 */
.react-calendar-heatmap .color-empty {
  fill: var(--primary-normal); /* 기본 배경색 */
}

.react-calendar-heatmap .color-scale-1 {
  fill: #e0f7f4; /* 약한 색 */
}

.react-calendar-heatmap .color-scale-2 {
  fill: #13cdbe; /* 주 색상 */
}

.react-calendar-heatmap .color-scale-3 {
  fill: #0b9d91; /* 중간 농도 */
}

.react-calendar-heatmap .color-scale-4 {
  fill: #067c73; /* 진한 농도 */
}

/* 레이블 스타일 */
.react-calendar-heatmap .react-calendar-heatmap-weekday-labels text {
  font-size: 10px;
  font-weight: 400; /* 굵기 조정 */
  fill: #333;
}

.react-calendar-heatmap .react-calendar-heatmap-month-labels text {
  font-size: 12px;
  font-weight: 500; /* 약간 굵게 */
  fill: #555;
}

/* 박스 간 hover 효과 */
.react-calendar-heatmap rect:hover {
  stroke: #333;
  stroke-width: 1.5px;
  cursor: pointer;
}

.jandiContainer {
  padding: 16px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
}

.react-calendar-heatmap .color-empty {
  fill: #ebedf0; /* Default empty cell */
}

.react-calendar-heatmap .color-scale-1 {
  fill: var(--primary-normal, #13cdbe);
}

.react-calendar-heatmap .color-scale-2 {
  fill: rgba(19, 205, 190, 0.5);
}

.react-calendar-heatmap .color-scale-3 {
  fill: rgba(19, 205, 190, 0.7);
}

.react-calendar-heatmap .color-scale-4 {
  fill: rgba(19, 205, 190, 1);
}

.react-calendar-heatmap text {
  font-size: 10px; /* Adjust label font size */
  font-weight: normal;
}

.tooltipStyle {
  background: #333;
  color: white;
  font-size: 12px;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
}
#y-label > div {
  width: 164px !important;
  height: 20px !important;
  margin-left: 10px !important;
  text-align: left !important;
  display: flex;
  align-items: center;
  padding-top: 4px !important;
  /* justify-content: center; */
  font-size: 12px !important; /* 텍스트 크기 */
  font-weight: bold !important;
  color: #333 !important; /* 텍스트 색상 */
}

#heatmap-row > div {
  display: flex;
  margin-bottom: 8px !important;
}
#x-labels-list > div {
  display: none !important;
}
