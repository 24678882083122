.page {
    padding: 4rem 0rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-color: var(--background-primary);
    box-sizing: border-box;
}

.mainContainer {
    flex-grow: 1;
    background-color: var(--static-white);
    border-radius: 2.4rem;
    padding: 2.4rem;
}

.title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
}

.description {
    font-size: 16px;
    color: #555;
    margin-bottom: 32px;
}

.button {
    background-color: #e0e0e0;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #c0c0c0;
}