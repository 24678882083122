.CustomTimePicker{
    width: 40rem;
    height: 4rem;
    padding: 0.8rem 1.6rem;
    background-color: var(--fill-normal);
    cursor: pointer;
    font-size: 1.6rem;
    border-radius: 0.8rem;
    border: none;
    color: var(--text-alternative);
    font-weight: 600;
    box-sizing: border-box;
}

.CustomTimePicker::placeholder{
    color: var(--text-assistive);
    font-weight: normal;
}

.CustomTimePicker:focus{
    outline: none;
}