.container {
  background-color: var(--background-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.customContainer {
  background-color: var(--static-white);
  width: 88rem;
  height: 75.2rem;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
.p1Text {
  font-weight: 600;
  font-size: 2.2rem;
}
.p2Text{
  font-weight: 600;
  font-size: 2.2rem;
  margin-top: 6.2rem;
 }
 .p3Text{
  font-weight: 600;
  font-size: 1.8rem;
 
 }
 .p4Text{
  font-weight: 600;
  font-size: 1.8rem;
 }
 .p5Text{
  font-weight: 600;
  font-size: 1.8rem;
 }
 .p6Text{
  font-weight: 600;
  font-size: 1.8rem;
 }
 .textContainer {
  display: flex;
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: space-between; 
  margin-top: 3rem;
}
 .customButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #E9ECEC; /* 검은색 테두리 추가 */
  background-color: var(--fill-normal);
  padding: 0.8rem 5rem;
}
.customButtonText {
  position: relative;
  font-size: 1.3rem;
  font-weight: 500; /* Semibold 두께 */
  color: #000000;
  margin: 0;
  padding: 0;
}

.customButton2 {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #E9ECEC;
  background-color: var(--fill-normal);
  padding: 0.8rem 20rem;
  padding-left: 1rem; /* 왼쪽 여백 조절 */
  margin-left: auto;
  margin-right: 1rem;
}
.customButtonText2 {
  font-size: 1.3rem;
  position: relative;
  font-weight: 500; 
  color: #8A9393;
  text-align: left; /* 왼쪽 정렬 */
  margin: 0;
  padding: 0;
}
.fileButton{
  margin-left: auto;
  display: flex;
}
.uploadInput {
  align-items: center;
  border-radius: 8px;
  border: 1px solid #E9ECEC;
  background-color: var(--fill-normal);
  width: 29rem;
  height: 3rem;
  color: #8A9393;
  margin-right: 1rem;
  padding-left: 1rem; /* 왼쪽 여백 조절 */
  font-size: 1.3rem;
}
.customButton3 {
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #E9ECEC; /* 검은색 테두리 추가 */
  padding: 1rem 1rem;
 
}
.customButtonText3{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #E9ECEC; /* 검은색 테두리 추가 */
  padding: 0.9rem 2.5rem;
  font-size: 1.3rem;
}
.fileInputHidden {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
}

.linkInput{
  
  display: inline-block;
  border-radius: 8px;
  border: 1px solid #E9ECEC;
  background-color: var(--fill-normal);
  width: 42rem;
  height: 3rem;
  padding-left: 1rem; 

}
.linkInput::placeholder {
  align-items: center;
 
  color: #8A9393;
  font-size: 1.3rem;
}


.customOkButton {
  width: 100%;
  background-color: #EBEBEB;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 3rem;
  margin-left: auto; /* 오른쪽으로 이동 */
  margin-bottom: 2.4rem;
}
.customOkButtonText {
  position: relative;
  font-size: 1.3rem;
  font-weight: 500; /* Semibold 두께 */
  color: #8A9393;
}
