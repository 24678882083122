.week-calendar {
  width: 100%;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.week-header {
  display: flex;
  justify-content: space-between;
  
}
.week-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}
.addPracticeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 32px;
  border: 1px solid var(--line-normal);
  border-radius: 8px;
  font-size: 14px;
  color: var(--text-alternative);
  cursor: pointer;
  padding: 0.2rem 0.5rem;
}
.calendar-container {
  display: flex;
  flex-direction: column;
  
}

.calendar-header {
  display: flex;
  background: var(--fill-assistive);
  border-radius: 12px;
  margin-bottom: 8px;
}

.calendar-body {
  display: flex;
  max-height: 140px; /* 최대 높이 설정 */
  overflow-y: auto; /* 세로 스크롤 활성화 */
 
}
.calendar-body::-webkit-scrollbar {
  display: none; /* 스크롤바 숨기기 */
}

/* Firefox */
.calendar-body {
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.calendar-cell {
  flex: 1;
  padding: 10px;
  text-align: center;
}
.day-label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 6px;
}

.date-label {
  font-size: 12px;
  color: var(--text-alternative);
}
.calendar-cell:last-child {
  border-right: none; /* 마지막 열 제거 */
}

.file-indicator {
  display: flex;
  width: 108px;
  height: 28px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: var(--primary-text);
  background: var(--primary-chip);
  cursor: pointer;
}
.practice-indicator {
  display: flex;
  width: 108px;
  height: 28px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: var(--text-alternative);
  background: var(--fill-normal);
  cursor: pointer;
}
